export const extractData = (input) => {
  const regex = /^(.+)<br \/>(.+)<br \/>(.+)$/;
  const match = input.match(regex);

  if (match && match.length === 4) {
    const name = match[1].trim().replace('<br />', '');
    const text = match[3].trim();
    return {name, text};
  }

  return null;
}

export const extractText = (msg) => {

  msg = msg.replace(/\[USER=(.*?)\](.*?)\[\/USER\]/g, '$2,');

  let extractMessageData = {
    answer: null,
    text: null
  }

  if (msg && msg.includes('------------------------------------------------------')) {

    const regex = /------------------------------------------------------<br \/>(.*)\[(.*)\]<br \/>(.*)------------------------------------------------------<br \/>(.*)/;
    const match = msg.match(regex);

    if (match && match.length === 5) {
      //console.log(match[1],match[2],match[3],match[4])
      const extractedText = match[3].trim();
      const followingText = match[4].trim();

      extractMessageData = {
        answer: extractedText,
        text: followingText
      }
    }

    const regex2 = /------------------------------------------------------<br \/><br \/>(.*)------------------------------------------------------<br \/>(.*)/;
    const match2 = msg.match(regex2);

    if (match2 && match2.length === 3) {
      //console.log(match[1],match[2],match[3],match[4])
      const extractedText = match2[1].trim();
      const followingText = match2[2].trim();

      extractMessageData = {
        answer: extractedText,
        text: followingText
      }
    }
  }
  if (extractMessageData.text === null) {
    extractMessageData.text = msg
  }

  return extractMessageData;
}
