const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};
export const useSubscribe = ({ publicKey }) => {
  const getSubscription = async () => {
    if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
      throw new Error('ServiceWorkerAndPushManagerNotSupported');
    }

    const registration = await navigator.serviceWorker.ready;

    if (!registration.pushManager) {
      alert('PushManagerUnavailable')
      throw new Error('PushManagerUnavailable');
    }

    const existingSubscription = await registration.pushManager.getSubscription();

    if (existingSubscription) {
      console.log('Уже подписаны ранее')
      //throw new Error('ExistingSubscription');
    }

    const convertedVapidKey = urlBase64ToUint8Array(publicKey);
    return await registration.pushManager.subscribe({
      applicationServerKey: convertedVapidKey,
      userVisibleOnly: true,
    });
  };

  return { getSubscription };
};