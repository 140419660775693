import Icon from "@components/Icon";
import {Link} from "react-router-dom";
import ModalCallsAccess from "./modals/ModalCallsAccess";
import {useEffect, useState} from "react";
import {useNotificationsStore} from "../store/notifications-store";
const Header = ({isAuth = false}) => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [notifications, getNotifications] = useNotificationsStore((state) => [state.notifications, state.getNotifications])
  const [newNotifications, setNewNotifications] = useState([])

  useEffect(() => {
    getNotifications()
  }, [])

  useEffect(() => {
    setNewNotifications(notifications.filter((item) => item.isNew === true))
  }, [notifications])
  return (
    <>
    <header className="header">
      <div className="header__flex container">
        {isAuth && (
          <div className="header__logo"></div>
        )}
        <div className="header__actions">
          {isAuth && (
            <>
              <Link className="header__call" to="/call">
                <Icon name="phone" />
              </Link>
              <Link className="header__notifications" to="/notifications">
                <Icon name="notifications" />
                {newNotifications.length > 0 && (
                  <span>{newNotifications.length}</span>
                )}
              </Link>
            </>
          )}
          {!isAuth && (
            <span className="header__call" data-popup="callsAccess" onClick={()=>setIsModalActive(true)}>
							<Icon name="phone" />
            </span>
          )}
        </div>
      </div>
    </header>
  <ModalCallsAccess active={isModalActive} onClose={()=>setIsModalActive(false)}/>
    </>
  );
}

export default Header;