import {format} from "date-fns";
import {ru} from "date-fns/locale";

function formatDateToCustomFormat(dateString) {
  // Преобразуем строку даты в объект Date
  let date;
  if (typeof dateString === 'string') {
    if (dateString.includes('T')) {
      // ISO 8601 формат
      date = new Date(dateString);
    } else {
      // Формат "18.10.2024 18:19:31"
      const [datePart, timePart] = dateString.split(' ');
      const [day, month, year] = datePart.split('.');
      const [hours, minutes, seconds] = timePart.split(':');
      date = new Date(year, month - 1, day, hours, minutes, seconds);
    }
  } else if (dateString instanceof Date) {
    // Если уже объект Date
    date = dateString;
  }
  else if (typeof dateString === 'number') {
    return format(dateString, 'd MMMM', { locale: ru });
  } else {
    throw new Error(typeof dateString);
  }

  // Форматируем дату в формат "d MMMM"
  return format(date, 'd MMMM', { locale: ru }); // Используем русский язык
}

export const prettyDate = (timestamp) => {

  return formatDateToCustomFormat(timestamp)
}

export const convertDate = (dateString) => {

  const datePattern = /^\d{2}\.\d{2}\.\d{4}$/;
  if (datePattern.test(dateString)) {
    // Разбиваем строку на части
    const parts = dateString.split('.');
    // Проверяем, что строка имеет правильный формат
    if (parts.length !== 3) {
      throw new Error('Неверный формат даты');
    }
    // Создаем объект Date с правильным порядком частей
    const date = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);

    // Проверяем, что дата корректна
    if (isNaN(date.getTime())) {
      throw new Error('Неверная дата');
    }
    // Возвращаем дату в формате ISO 8601
    return date.toISOString().split('T')[0];
  }
}

export const validateDate = (value) => {

  const currentDate = new Date ();
  const selectedDate = new Date(convertDate(value))

  return (
    selectedDate < currentDate || "Некорректная дата"
  );
};

// формат hh:mm
export const extractTime = (dateString) => {
  const date = new Date(dateString);

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
}
