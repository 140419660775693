import {useEffect, useRef, useState} from "react";
import {useNotificationsStore} from "../store/notifications-store";
import cn from "classnames";
import {format} from "date-fns";
import {ru} from "date-fns/locale";

const NotificationWrapper = ({children, link}) => {
  if (link) {
    return (<a href={link} target="_blank" className="notification-wrapper">{children}</a>)
  } else {
    return (<div className="notification-wrapper">{children}</div>)
  }
}

const Notification = ({id, title, body, date_create, link, isNew}) => {
  const [setNotificationStatus] = useNotificationsStore((state) => [state.setNotificationStatus])
  const [isInView, setIsInView] = useState(false);
  const [showNewDot, setShowNewDot] = useState(isNew);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      {
        threshold: 1, // Порог пересечения 100%
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      setNotificationStatus(id)
      setShowNewDot(false)
    }
  }, [isInView]);

  return (
    <NotificationWrapper link={link}>
      <div className="notification" ref={ref}>
        <div className="notification__title">{title}</div>
        {isNew && (
          <div className={cn('notification__status-new', {'notification__status-new--hidden': !showNewDot})}></div>
        )}
        <div className="notification__text">{body}</div>
        <div className="notification__date">{format(date_create, 'd MMMM', {locale: ru})}</div>
      </div>
    </NotificationWrapper>
  )
}

export default Notification;