import LayoutWithTopBlock from "../components/layouts/LayoutWithTopBlock";
import {useEffect, useState} from "react";
import {useNotificationsStore} from "../store/notifications-store";
import Notification from "../components/Notification";

const PageNotifications = () => {
  const [notifications, getNotifications] = useNotificationsStore((state) => [state.notifications, state.getNotifications])
  const [newNotifications, setNewNotifications] = useState([])
  const [oldNotifications, setOldNotifications] = useState([])

  useEffect(() => {
    getNotifications()
  }, [])

  useEffect(() => {
    setNewNotifications(notifications.filter((item) => item.isNew === true))
    setOldNotifications(notifications.filter((item) => item.isNew === false))
  }, [notifications])
  return (
    <LayoutWithTopBlock isTopBlockFixed={false}>
      <div className="main bg_2">
        <div className="container pb_152">
          {newNotifications.length > 0 && (
            <section className="notifications-section">
              <div className="notifications-section__title">Непрочитанные</div>
              <div className="notifications">
                {newNotifications.map((item) => (
                  <Notification {...item} key={item.id}/>
                ))}
              </div>
            </section>
          )}
          {oldNotifications.length > 0 && (
            <section className="notifications-section">
              <div className="notifications-section__title">Все уведомления</div>
              <div className="notifications">
                {oldNotifications.map((item) => (
                  <Notification {...item} key={item.id}/>
                ))}
              </div>
            </section>
          )}
        </div>
      </div>
    </LayoutWithTopBlock>
  );
}

export default PageNotifications;