import React, {useEffect, useRef, useState} from 'react';
import MessageItem from "./message-item";
import {useChatStore} from "../../store/chat-store";
import {prettyDate} from "../../utils/date";
import {ChatDate} from "./chat-date";

const MessageList = () => {
    window.__localeId__ = "ru";
    const chat = useChatStore((state) => state.chat);
    const updateChat = useChatStore((state) => state.updateChat);

    const chatEndRef = useRef(null);
    const [fixedDate, setFixedDate] = useState();
    const [loading, setLoading] = useState(false);
    const [allLoaded, setAllLoaded] = useState(false);
    const [scroll, setScroll] = useState();
    const [prevScroll, setPrevScroll] = useState(0);
    const scrollRef = useRef(0);

    let tmpDate = null;

    const scrollToBottom = () => {
      if (chatEndRef.current) {
        setTimeout(() => {
          chatEndRef.current.scrollIntoView({behavior: "smooth"});
        }, 100);
      }
    };

  useEffect(() => {
    setTimeout(() => {
      chatEndRef.current.scrollIntoView({behavior: "auto"})
    },300)
  }, []);

    useEffect(() => {
      // Первая загрузка
      updateChat().then(() => {
        if (chatEndRef.current) {
          chatEndRef.current.scrollIntoView();
        }
        setScroll(window.scrollY)
      })
      // Обработка скрола
      const handleScroll = () => {
        setScroll(window.scrollY);
      };
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [])

    useEffect(() => {
      if (!allLoaded && scroll === 0 && scroll < prevScroll) {
        setLoading(true)
        scrollRef.current = window.scrollY

        //alert(chat[0]?.message_id)
        updateChat(chat[0]?.message_id)
          .then((res) => {            //window.scrollTo(0,scrollRef.current);
            if(chat[0]?.message_id) {
              const mess = document.querySelector(`.message[data-id="${chat[0]?.message_id}"]`);
              window.scrollTo({
                top: mess.offsetTop,
              });
            }
            if (res && res === 'end') setAllLoaded(true);
          })
          .finally(() => {
            setTimeout(() => {
              setLoading(false)
            }, 3000)
          });
      }
      setPrevScroll(scroll);
    }, [scroll]);


    return (
      <div className="main chat">
        <div className="chat__flex container container_12">
          {fixedDate && <div className="chat__date-fixed">{prettyDate(fixedDate)}</div>}
          {loading && <div className="chat__loading">
            <div className="dot-flashing"></div>
          </div>}
          {chat.length ? chat.map((message, index) => {
            const separatorDate = (tmpDate === prettyDate(message?.date) ? null : prettyDate(message?.date))
            if (!tmpDate || tmpDate !== prettyDate(message?.date)) tmpDate = prettyDate(message?.date);
            return (
              <span key={message.id}>
                {separatorDate && (<ChatDate date={separatorDate}/>)}
                {/*JSON.stringify(message)*/}
                <MessageItem  message={message}/>
              </span>
            )
          }) : null}
          <span ref={chatEndRef} className="chat__end"></span>
        </div>
      </div>
    );
  }
;

export default MessageList;
