import {Navigate, Outlet} from "react-router-dom";
import {useUserStore} from "../store/user-store";
import {useEffect} from "react";
import {apiChatServer} from "../api/chatApi";
import {useChatStore} from "../store/chat-store";
import {useNotificationsStore} from "../store/notifications-store";

const RequiredAuth = () => {
  const [isAuthenticated] = useUserStore((state) => [state.isAuthenticated])
  const [addChatMessage, setHaveNewMessages] = useChatStore((state) => [state.addChatMessage, state.setHaveNewMessages]);
  const [getNotifications] = useNotificationsStore((state) => [state.notifications, state.getNotifications])
  const connectWS = () => {
    apiChatServer().then((res) => {
      console.log('apiChatServer', res.data)
      let socket = new WebSocket(res.data);

      socket.onopen = function (e) {
        console.log("[open] Соединение установлено");
      };

      socket.onmessage = function (e) {
        console.log("on message");
        const chatEnd = document.querySelector('.chat__end')
        let data = null
        try {
          data = JSON.parse(e.data.replace('#!NGINXNMS!#', '').replace('#!NGINXNME!#', ''));
          console.log('mess:', data)
          setHaveNewMessages(true)
        } catch {
          console.log('socket error: не удалось распарсить сообщение', e.data)
        }

        console.log('data', data)

        if (data) {
          const command = data.text.command
          const messageList = data.text.params

          console.log('command', command)

          if (command === "OnSendMessage") {
            for (const messageId in messageList) {
              const message = messageList[messageId]

              if (message.FILES && message.FILES.length) {
                message.FILES.forEach((file) => {
                  console.log('f', file.TIMESTAMP_X)
                  addChatMessage({
                    id: message.id,
                    senderStatus: 'clinic',
                    senderName: message.authorName,
                    email: 'email',
                    text: '',
                    file: {
                      name: file.ORIGINAL_NAME,
                      src: file.SRC,
                      type: file.CONTENT_TYPE.includes('image') ? 'image' : 'file',
                    },
                    date: file.TIMESTAMP_X,
                  })
                })
              } else {
                console.log('m', message.date)
                addChatMessage({
                  id: message.id,
                  senderStatus: 'clinic',
                  senderName: message.author,
                  email: 'email',
                  text: message.text,
                  date: message.date,
                })
              }

              if (chatEnd) {
                setTimeout(() => {
                  chatEnd.scrollIntoView({behavior: "smooth"});
                }, 100);
              }
            }
          }
          if (command === "OnSendNotify") {
            getNotifications()
          }
        }
      };

      socket.onclose = function (event) {
        if (event.wasClean) {
          console.log(`[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`);
        } else {
          console.log('[close] Соединение прервано');
        }
      };

    })
      .catch((err) => {
        console.log('socket error: ', err)
      })
  }
  useEffect(()=>{
    connectWS()
  },[isAuthenticated])
  return isAuthenticated ? <Outlet /> : <Navigate to="/" replace />;
}

export default RequiredAuth;
