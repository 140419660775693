import {Link} from "react-router-dom";
import {useForm} from "react-hook-form";

import Icon from "../Icon";
import InfoBlock from "../InfoBlock";
import MaskedInput from "../MaskedInput";
import {useUserStore} from "../../store/user-store";
import {useState} from "react";

const ScreenPhone = ({mode, toPrevStep, toNextStep}) => {
  const [setPhone, sendPhoneNumber] = useUserStore((state) => [state.setPhone, state.sendPhoneNumber])
  const {register, handleSubmit, formState, setError} = useForm();
  const [consent, setConsent] = useState(false);

  const onSubmit = (phone) => {
    const clearPhone = phone.replace(/[^0-9+]/g, '')
    if(clearPhone.length!== 12) {
      setError("phone", {message: "Неверный номер телефона"})
      return
    }
    setPhone(clearPhone)
    if(mode==='verifyStart') {
      sendPhoneNumber(clearPhone).then((res)  =>  {
        if(res?.response?.status === 400) {
          if (res?.response?.data.errors[0].code === "ERR_SMS_RU") {
            setError('phone', {message: res?.response?.data.errors[0].message})
            return
          }
          setError('phone', {message: 'Неверный номер телефона'})
        } else {
          toNextStep()
        }
      })
    }
  }

  return (
    <form className="container auth-step" onSubmit={handleSubmit((data) => onSubmit(data.phone))}>
      <button className="auth__back" onClick={toPrevStep} type="button">
        <Icon name="angle-left"/>
      </button>
      <div className="content-block content-block--screen-phone">
        <div className="auth__title">
          <h1 className="h1">Введите номер телефона</h1>
        </div>
        <div className="fieldset">
          <MaskedInput
            labelText="Номер телефона"
            mask="+7 (000) 000-00-00"
            error={formState.errors.phone?.message}
            {...register("phone", {required: "Введите телефон"})}
          />
        </div>
        <InfoBlock/>
        <div className="field agreement-box">
          <div className="checkbox agreement">
            <input onChange={() => setConsent(!consent)} checked={consent} className="checkbox__input validate-field" type="checkbox" id="agreement" name="agreement"/>
            <div className="checkbox__check">
              <Icon name={'check'}/>
            </div>
            <label className="checkbox__label" htmlFor="agreement">
              Даю согласие на обработку персональных данных, определенных в <Link className="link" to="https://korrektsiya-zreniya.ru/politika-confidencialnosti/">Согласии
              на обработку персональных данных</Link>
            </label>
            <div className="field__error"/>
          </div>
        </div>
      </div>
      <div className="bottom-block">
        <button className="button" type="submit" disabled={formState.isSubmitting || !consent}>Продолжить</button>
      </div>
    </form>
  )
}
export default ScreenPhone