import React from 'react';
import Icon from "../Icon";
import {useNavigate} from "react-router-dom";

const HeaderBackButton = () => {

  const navigate = useNavigate();

  const goBack = () => {
    if (window.history?.length && window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/main-auth', { replace: true });
    }
  }

  return (
    <button onClick={goBack} className="top-block__btn top-block__btn_left">
      <Icon name="angle-left" />
    </button>
  );
};

export default HeaderBackButton;