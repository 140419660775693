import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useUserStore} from "../store/user-store";
import {deviceType} from "../utils/getDeviceType";
import {apiChatServer} from "../api/chatApi";
import {useChatStore} from "../store/chat-store";

const AuthDispatcher = () => {
  const [pin, accessToken, refreshToken, setIsAuthenticated] = useUserStore((state) => [state.pin, state.accessToken, state.refreshToken, state.setIsAuthenticated])
  const navigate = useNavigate();

  useEffect(() => {
    if (pin && accessToken && refreshToken) {
      deviceType(); // Получаем тип устройства
      const device = localStorage.getItem('deviceType');

      if (device !== 'web') {
        // Если устройство не web, перенаправляем на страницу логина и сбрасываем аутентификацию
        setIsAuthenticated(false);
        navigate('/login');
      } else {
        // Если устройство web, устанавливаем аутентификацию и перенаправляем на защищенную страницу
        setIsAuthenticated(true);
        navigate('/main-auth');
      }
    }
    // Обработка других условий
    else if (pin && !accessToken && !refreshToken) {
      navigate('/login-auth');
    } else if (!pin && accessToken && refreshToken) {
      navigate('/login-new-password');
    } else if (!pin && !accessToken && !refreshToken) {
      navigate('/main');
    }
  }, []);
}
export default AuthDispatcher
