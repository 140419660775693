import axios from 'axios';
import {buildStorage, buildWebStorage, setupCache} from 'axios-cache-interceptor';
import { clear, del, get, set } from 'idb-keyval';


const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',


    'Cache-Control': 'no-store, no-cache, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': '0',
  },
  withCredentials: true
});

const indexedDbStorage = buildStorage({
  async find(key) {
    const value = await get(key);

    if (!value) {
      return;
    }

    return JSON.parse(value);
  },

  async set(key, value) {
    await set(key, JSON.stringify(value));
  },

  async remove(key) {
    await del(key);
  }
});

/*const apiClient = setupCache(apiClientBase, {
  storage: indexedDbStorage
});*/

apiClient.interceptors.request.use(config => {
  const token = localStorage.getItem('access_token');
  console.log(token);
  if (token) {
    config.headers['X-Authorization'] = `Bearer ${token}`;
  }
  return config;
});

apiClient.interceptors.response.use(response => {
  if(response?.status=== 'error') {
    return Promise.reject(response)
  }
  return response;
}, error => {
  const { response, config } = error
  const accessToken = localStorage.getItem('access_token')
  const refreshToken = localStorage.getItem('refresh_token')

  if(response?.status === 401 && accessToken && refreshToken) {
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/refresh-token`, {
      accessToken,
      refreshToken
    })
      .then((res) => {
        if(res?.data?.status !== 'error' && res.data.data.accessToken && res.data.data.refreshToken) {
          localStorage.setItem('access_token', res.data.data.accessToken);
          localStorage.setItem('refresh_token', res.data.data.refreshToken);
          return apiClient(config)
        }
        else {
          //return Promise.reject(error)
        }
        return apiClient(config)
      })
      .catch(() => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        window.location.reload();
        //return Promise.reject(error)
      })
  }

  return error
})

export default apiClient;