import Icon from "../Icon";
import {motion} from "framer-motion";
import {useEffect, useState} from "react";
import {useUserStore} from "../../store/user-store";
import apiClient from "../../api/axiosConfig";
import {useSubscribe} from "../../utils/hooks/useSubscribe";
import {pushPublicKey} from "../../utils/consts";

const ModalSubscribe = () => {
  const {phone} = useUserStore((state) => state);
  const [submitPersonalInfo] = useUserStore((state) => [state.submitPersonalInfo])
  const [active, setActive] = useState(false);
  const onClose = () => {
    setActive(false);
  }

  const push = useSubscribe({publicKey: pushPublicKey})

  //Показываем только раз для одного пользователя
  useEffect(() => {
    if(!localStorage.getItem(`subscribeShownFor${phone}`)) {
      setActive(true);
    }
    localStorage.setItem(`subscribeShownFor${phone}`, 'true');
  }, [])

  const subscribeHandler = () => {
    if (Notification.permission === "denied") {
      alert("Оповещения отклонены, пожалуйста включите их в браузере")
      localStorage.removeItem('push');
      return
    }
    push.getSubscription().then(sub => apiClient.post(`/push/subscribe`, JSON.stringify(sub))).then(() => {
      submitPersonalInfo({'push': true}).then(() => {
        localStorage.setItem('push', 'true');
      })
    }).catch(() => {
      alert("Ошибка подписки")
      localStorage.removeItem('push');
    })
    setActive(false);
  };

  const show = {
    opacity: 1,
    display: "block",
  };

  const hide = {
    opacity: 0,
    transitionEnd: {
      display: "none"
    }
  };

  return (
    <motion.div
      animate={active ? show : hide}
      className={`modal modal_w-close modal modal-subscribe ${active ? 'modal--show' : ''}`}
    style={{'display':'none'}}>
      <div className={"modal-subscribe__container container"}>
        <div onClick={onClose} className="modal__close modal-close">
          <Icon name="close"/>
        </div>
        <img className="modal-subscribe__image" src="./assets/images/image-bell.png" alt=""/>
        <h2 className={"modal-subscribe__title"}>Не пропустите<br/>Запись на коррекцию</h2>
        <p className={"modal-subscribe__text"}>Разрешите отправку уведомлений, чтобы мы могли напомнить о приёме</p>
        <div className={"modal-subscribe__btn-box"}>
          <button className="button modal-subscribe__btn" onClick={() => {
            subscribeHandler()
          }}>Разрешить
          </button>
          <button className="button button_color-2 modal-subscribe__btn" onClick={onClose}>Не сейчас</button>
        </div>
      </div>
    </motion.div>
  )
}

export default ModalSubscribe