import apiClient from './axiosConfig';
import axios from "axios";
import {useChatStore} from "../store/chat-store";
import {useUserStore} from "../store/user-store";



export const apiChatSendMessage = async (message, fileInput, replyMessageData) => {
  const formData = new FormData();
  formData.append('message', message);
  formData.append('name', localStorage.getItem('name'));
  formData.append('phone', localStorage.getItem('phone'));
  formData.append('email', localStorage.getItem('email'));

  if (replyMessageData) {
    formData.append('attachments', JSON.stringify([replyMessageData]));
  }
const haveFiles = fileInput && fileInput.files && fileInput.files.length > 0
  if (haveFiles) {
    formData.append('files', fileInput.files[0]);
  }

  const response = await apiClient.post(
    '/im/send-message',
    formData, {
      headers: {
        'Content-Type': haveFiles ? 'multipart/form-data' : 'application/json'
      }
    }
  );
  return response?.data;
};

export const apiChatServer = async () => {
  const response = await apiClient.get(
    '/pull/get-channel'
  );
  return response?.data;
};



/*export const apiChatSendMessage = async (message, fileInput) => {
  const formData = new FormData();

  formData.append('message', message);
  formData.append('name', localStorage.getItem('name'));
  formData.append('phone', localStorage.getItem('phone'));
  formData.append('email', localStorage.getItem('email'));
  formData.append('files', fileInput.files[0] || '');
  formData.append('type', 'send_message');


  const response = await apiClient.post(
    // 'https://b24barcelona.m-works.ru/pub/b24test/ajax.php',
    'https://b24barcelona-stage.m-works.ru/pub/b24test/ajax.php', //для m-stage.barcelona.m-works.ru
    formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  return response.data;
};*/

/*export const apiChatGetHistory = async () => {
  const formData = new FormData();
  formData.append('name', localStorage.getItem('name'));
  formData.append('phone', localStorage.getItem('phone'));
  formData.append('type', 'chat_history');
  const response = await apiClient.post(
    // 'https://b24barcelona.m-works.ru/pub/b24test/ajax.php',
    'https://b24barcelona-stage.m-works.ru/pub/b24test/ajax.php', //для m-stage.barcelona.m-works.ru
    formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return response?.data;
}*/



export const apiChatGetHistory = async () => {

  const response = await apiClient.get('/im/get-messages');
  return response?.data;

}

export const apiChatShowMore = async (messageId) => {

  const response = await apiClient.get(`/im/get-more/${messageId}`);
  return response?.data;

}
