import apiClient from './axiosConfig';

const fakeAdv = {
  data: {
    items: [
      {
        "icon": "adv-building",
        "title": "15 лет работы",
        "text": "В 2009 г. присоединилась первая клиника"
      },
      {
        "icon": "adv-pin-spark",
        "title": "15 городов",
        "text": "В 2024 г. в программе участвуют 19 клиник из 15 городов"
      },
      {
        "icon": "adv-laser",
        "title": "132 000 операций",
        "text": "Более 132 000 операций проведено по нашей программе"
      },
      {
        "icon": "adv-eye",
        "title": "Цена за два глаза",
        "text": "Указываем цены сразу за оба глаза"
      },
      {
        "icon": "adv-rub",
        "title": "Фиксированная стоимость",
        "text": "Цена не зависит от степени близорукости и астигматизма"
      },
      {
        "icon": "adv-smile",
        "title": "Бесплатная докоррекция",
        "text": "В течение 10 лет после операции"
      }
    ]
  }
}

const fakeAdvAuth = {
  data: {
    items: [
      {
        "icon": "adv-eye",
        "title": "Цена за два глаза",
        "text": "Указываем цены сразу за оба глаза"
      },
      {
        "icon": "adv-rub",
        "title": "Фиксированная стоимость",
        "text": "Цена не зависит от степени близорукости и астигматизма"
      },
      {
        "icon": "adv-smile",
        "title": "Бесплатная докоррекция",
        "text": "В течение 10 лет после операции"
      }
    ]
  }
}

// Получение преимуществ
export const apiGetAdvantages = async (auth) => {
  return auth ? fakeAdvAuth : fakeAdv
  //const response = await apiClient.get(`/advantages?nPageSize=9999&page=1`, {cache: {interpretHeader: false}});
  //return response.data;
};
