import { create } from 'zustand';
import {apiGetAdvantages} from "../api/advantagesApi";
import {apiGetNotifications} from "../api/notificationsApi";

export const useNotificationsStore = create((set, get) => ({
  notifications: [],
  oldNotifications: localStorage.getItem('old_notifications_list') || '',
  getNotifications: async (lastMessageId) => {
    const response = await apiGetNotifications(lastMessageId)
    ////// логика хранения в local storage id прочитанных сообщений
    let data = []
    if(response.data.length) {
      data = await response.data.map((item)=> {
        return {...item, isNew: !get().oldNotifications.split(',').includes(item.id)}
      })
    }
    //////
    set({notifications: data})
  },
  setNotificationStatus: async (messageId) => {
    ////// логика хранения в local storage id прочитанных сообщений
    const list = get().oldNotifications.length ? new Set(get().oldNotifications.split(',')) : new Set()
    list.add(messageId)
    set({oldNotifications: [...list].join(',')})
    await localStorage.setItem('old_notifications_list', get().oldNotifications)
    console.log(localStorage.getItem('old_notifications_list'))
    //////
    // TODO: отправка статуса прочтения на сервер
  }
}))