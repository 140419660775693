import {useEffect, useState} from "react";
import { useNavigate} from "react-router-dom";
import {useUserStore} from "../../store/user-store";

const ModalWrongCode = ({active, onClose}) => {
  const navigate = useNavigate();
  const [logout] = useUserStore((state) => [state.logout])

  const [classVisible, setClassVisible] = useState('');
  const [classFade, setClassFade] = useState('');

  useEffect(() => {
    if (active) {
      setClassVisible('modal_visible');
      setTimeout(() => {
        setClassFade('modal_fade');
      }, 1);
    } else {
      setClassFade('');
      setTimeout(() => {
        setClassVisible('');
      }, 300);
    }
  }, [active]);

  const logOut = (e) => {
    e.preventDefault();
    logout();
    onClose();
    navigate("/main");
  }

  return (
    <div className={`modal modal modal-logout ${classVisible} ${classFade}`}>
      <div className="modal__inner">
        <div className="modal__wrap">
          <div className="modal__content">
            <h2 className="h2 modal__title mb_24">Код введен неправильно 5 раз</h2>
            <div className="text modal__text">Запросите код повторно</div>
            <div className="modal__buttons">
              <button onClick={(e)=>{
                logOut(e);
                onClose()
              }} className="button">Понятно</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ModalWrongCode